import { Button, Card, Col, Image, Placeholder, Row } from "react-bootstrap"
import Icon from "react-crypto-icons"
import { formatCompactAddress, placeholderImage } from "../functions"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useHistory } from "react-router";
import moment from "moment";
import Countdown from "./Countdown";
import BuyButton from "./buttons/BuyButton";
import * as backend from '../build/index.main.mjs'
import BidButton from "./buttons/BidButton";
const NFTNameStyle =
{
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "bold",
  "fontSize": "28px",
  "lineHeight": "33px",
  "color": "black"
}
const lgText = { "fontSize": "28px" }
const mdText = { "fontSize": "24px" }
const smText = { "fontSize": "16px" }
function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}
const BrowseCard = ({
  acc,
  stdlib,
  addr,
  escrow,
  asset,
  owner,
  status,
  onClick,
  onUpdate,
  image,
  amount,
  rewards,
  index,
  sold,
  appId,
  minBid,
  endSecs,
  reservePrice,
  startPrice,
  app,
  children
}) => {
  const history = useHistory()
  const showBuy = moment().unix() > moment.unix(parseInt(app?.endSecs)).unix()
  const handleBuy = async () => {
    let ctc = await acc.contract(backend, appId)
    console.log({ ctc })
    acc.tokenAccept(asset?.id)
      .then(() => ctc.apis.Bid.touch(null)
        .then(() => ctc.apis.Bid.getPurchase(stdlib.parseCurrency(reservePrice))
          .then((msg) => {
            console.log({ msg })
            setTimeout(() => onUpdate(), 4000)
          })
          .catch((e) => {
            console.log(e)
            console.log("Purchase not allowed")
          })))
  }
  return <Card
    style={{
      borderRadius: "20px",
      overflow: "hidden",
      border: "0",
      boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.1)",
      ...smText
    }}>
    {index > 6
      ? <Card.Img
        onClick={() => onClick(escrow)}
        //as={LazyLoadImage} src={`/images/${assetId}/thumb/file`} alt={asset?.name} />
        as={LazyLoadImage} src={image} alt={asset?.name} />
      : <Card.Img
        onClick={() => onClick(escrow)}
        //src={`/images/${assetId}/file`} alt={asset?.name} />}
        src={image} alt={asset?.name} />}
    <Card.Body style={{
    }}>
      <Row style={{ textAlign: "left" }}>
        <Col xs={12} style={{ ...NFTNameStyle, ...mdText }}>
          {asset?.name}
        </Col>
        {asset?.creator === owner
          ? <Col xs={12}>{formatCompactAddress(asset?.creator)}</Col>
          : <Col xs={12}>{formatCompactAddress(asset?.creator)} / {formatCompactAddress(owner)}</Col>}
        {!showBuy && appId !== 427207800 /*goat*/ && (amt => amt > 0 ? <>
          <Col xs={6} style={{ ...NFTNameStyle, ...lgText }}><Icon size={20} name="algo" />&nbsp;{amt}</Col>
          {sold && <Col xs={6} style={{ ...NFTNameStyle, ...lgText, color: "red", textAlign: "right" }}>SOLD</Col>}
        </> : "")(Math.max(
            status === 'live' 
              ? parseFloat((amount-rewards-200000)/1000000)
              : parseFloat((amount-rewards)/1000000),
            parseFloat(minBid || 0),
            parseFloat(startPrice || 0)
          ))}
        {appId !== 427207800 /*goat*/ ? <>
          {endSecs && <>
          <Col className={"d-xs-block d-md-none"} xs={12}>
            <Countdown updateInterval={4000} initialEndMoment={moment.unix(parseInt(endSecs))} showBlocks={false} />
          </Col>
          <Col className={"d-none d-md-block"} xs={12}>
            <Countdown updateInterval={4000} initialEndMoment={moment.unix(parseInt(endSecs))} showBlocks={true} />
          </Col>
           
          </>}
          {false && showBuy && reservePrice !== "0" ? <>
            <Col xs={3}>&nbsp;</Col>
            <Col xs={9}><BuyButton onClick={handleBuy} reservePrice={reservePrice} /></Col>
          </> : ""}
        </> : ""}
      </Row>
      <Row className="mt-3">
        <Col>
          {children}
        </Col>
      </Row>
    </Card.Body>
  </Card>
}
export default BrowseCard