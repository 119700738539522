import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
} from "react-router-dom"
import {
  SnackbarProvider
} from 'notistack';
import { FeatureFlagContext, features } from './context/FeatureFlagContext'
const { REACT_APP_STAGE } = process.env

ReactDOM.render(
  <React.StrictMode>
    <FeatureFlagContext.Provider value={features[REACT_APP_STAGE]}>
      <SnackbarProvider maxSnack={1} hideIconVariant>
        <Router>
          <App />
        </Router>
      </SnackbarProvider>
    </FeatureFlagContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
