import { Card, Placeholder } from "react-bootstrap"
import image from '../placeholder.png'
function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}
const PlaceholderCard = ({
  placeholder,
}) => {
  return <>
    <Card
      style={{
        borderRadius: "20px",
        overflow: "hidden",
        border: "0",
        boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.1)",
      }}>
      <Card.Img src={image} alt="placeholder" />
      <Card.Body>
        <Placeholder style={{ textAlign: "left" }} as={Card.Title} animation="glow">
          <Placeholder xs={5 + getRandomInt(2)} />
        </Placeholder>
        <Placeholder style={{ textAlign: "left" }} as={Card.Text} animation="glow">
          <Placeholder xs={7 + getRandomInt(2)} />
        </Placeholder>
        <Placeholder style={{ textAlign: "left" }} as={Card.Text} animation="glow">
          <Placeholder xs={3 + getRandomInt(2)} />
        </Placeholder>
      </Card.Body>
    </Card>
  </>
}
export default PlaceholderCard