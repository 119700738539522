
export const ERROR_UNKNOWN = "ERROR UNKNOWN"
export const ERROR_REJECT_SIGN_TRANSACTION = "USER REJECT SIGN TRANSACTION" 
// returnes known and unknown error messages
export const errorMessage = (e) => {
  if(e.message.match(/[^:]*. sendrecv m[0-9] undefined --- ABORT/)) {
    return ERROR_REJECT_SIGN_TRANSACTION
  }
}
// handle error using user defined function
export const errorHandler = (expect, e, f) => {
  let error
  if(expect.includes(errorMessage(e))) {
    error = errorMessage(e)
  } else {
    error = ERROR_UNKNOWN
  }
  f(error)
}
// log error to console
export const errorLog = (expect, e) =>
  errorHandler(expect, e, console.dir)