import { Spinner } from "react-bootstrap";

const buttonStyle =
{
  "height": "42px",
  "lineHeight": "42px",
  "background": "linear-gradient(111.85deg, #FB87FF -23.82%, #AE44ED 119.4%)",
  "boxShadow": "0px 10px 20px rgba(219, 134, 255, 0.66)",
  "borderRadius": "44px",
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "normal",
  "fontSize": "14px",
  "textAlign": "center",
  "textTransform": "uppercase",
  "color": "#FFFFFF"
}

const ClosingButton = () => <>
  <div
    role="button"
    style={{
      ...buttonStyle,
      "background": "linear-gradient(111.85deg, rgb(255 193 7 / 68%) -23.82%, #ffc107 119.4%)",
      "boxShadow": "#ffc10736 0px 10px 20px",
      "opacity": ".9"
    }}>
    <Spinner
      as="span"
      animation="grow"
      size="sm"
      role="status"
      aria-hidden="true"
      style={{ "height": "12px", "width": "12px" }}
    />Claiming NFT ...
  </div>
</>
export default ClosingButton;