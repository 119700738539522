import { Spinner } from "react-bootstrap"

const regularButtonStyle =
{
  "marginTop": "40px"
}
const loadingButtonStyle =
{
  "marginTop": "40px",
  "opacity": ".9"
}
const buttonStyle =
{
  "height": "50px",
  "background": "linear-gradient(111.85deg, #FB87FF -23.82%, #AE44ED 119.4%)",
  "boxShadow": "0px 10px 20px rgba(219, 134, 255, 0.66)",
  "borderRadius": "44px",
  "marginTop": "46px"
}
const buttonTypographyStyle =
{
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "normal",
  "fontSize": "14px",
  "lineHeight": "50px",
  "textAlign": "center",
  "textTransform": "uppercase",
  "color": "#FFFFFF"
}
const finalLoadingButtonStyle =
{
  ...buttonStyle,
  ...buttonTypographyStyle,
  ...loadingButtonStyle
}
const finalRegularButtonStyle =
{
  ...buttonStyle,
  ...buttonTypographyStyle,
  ...regularButtonStyle
}
const SubmitButton = (props) => {
  const {
    loading,
    label,
    onClick
  } = props
  return !loading
    ? <div role="button" style={finalRegularButtonStyle} onClick={() => { onClick() }}>
      {label}
    </div> :
    <div style={finalLoadingButtonStyle} disabled><Spinner
      as="span"
      animation="grow"
      size="sm"
      role="status"
      aria-hidden="true"
      style={{
        "height": "12px",
        "width": "12px"
      }}
    />Loading...</div>
}
export default SubmitButton