import { useEffect, useState } from "react"
import moment from 'moment';
import {
  getMomentDiff,
  bigNumberToNumberFromSome,
} from '../functions'
const labelStyle =
{
  "height": "17px",
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "300",
  "fontSize": "14px",
  "lineHeight": "17px",
  "color": "#484959",
  "flex": "none",
  "order": "0",
  "flexGrow": "0",
  "margin": "0px 2px"
}
const valueStyle =
{
  "height": "17px",
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "bold",
  "fontSize": "14px",
  "lineHeight": "17px",
  "color": "#C75AF3",
  "flex": "none",
  "order": "1",
  "flexGrow": "0",
  "margin": "0px 2px"
}
const Countdown = ({
  stdlib,
  v,
  initialEndMoment,
  showBlocks = true,
  updateInterval = 1000
}) => {
  const [endMoment, setEndMoment] = useState(initialEndMoment)
  const [now, setNow] = useState(moment())
  useEffect(() => {
    const interval = setInterval(() => {
      setNow(moment())
    }, updateInterval)
    return () => clearInterval(interval)
  }, [])
  useEffect(() => {
    if(!v || !stdlib) return
    const interval = setInterval(() => {
      v.endSecs()
        .then((endSecs) => bigNumberToNumberFromSome(stdlib, endSecs))
        .then((endSecs) => setEndMoment(moment.unix(endSecs)))
    }, 10 * 1000)
    return () => clearInterval(interval)
  }, [stdlib, v])
  return now.unix() > endMoment.unix()
    ? false && <><span style={{background: "white", padding: "5px", borderRadius: "5px"}}>
      <span style={labelStyle}>Sale</span>
      <span style={valueStyle}>ended</span>
    </span></>
    : <span style={{background: "white", padding: "5px", borderRadius: "5px"}}>
      <span style={labelStyle}>Sale ends in</span>
      <span style={{
        ...valueStyle,
        "display": "inline-flex",
        "columnGap": "5px",
      }}>
        {getMomentDiff(endMoment).map(([el, period]) => period > 0
          && <span>
            <span>{String(period).padStart(2, '0')}</span>
            <span>{el.substr(0, 1)}</span>
          </span>)}
        {showBlocks && <span>({Math.round((endMoment.unix() - now.unix()) / 4.20)} blocks)</span>}
      </span>
    </span>
}
export default Countdown;