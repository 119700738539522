import { InputAdornment, TextField } from "@mui/material";
import { Spinner } from "react-bootstrap";
import Icon from "react-crypto-icons";
const buttonStyle =
{
  "height": "42px",
  "lineHeight": "42px",
  "background": "linear-gradient(111.85deg, #FB87FF -23.82%, #AE44ED 119.4%)",
  "boxShadow": "0px 10px 20px rgba(219, 134, 255, 0.66)",
  "borderRadius": "44px",
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "normal",
  "fontSize": "14px",
  "textAlign": "center",
  "textTransform": "uppercase",
  "color": "#FFFFFF"
}
const BuyingButton = ({
  reservePrice,
  onClick,
}) => {
  let label = "Loading..."
  let style = {
    ...buttonStyle,
    "marginTop": "15px",
    "opacity": ".9"
  }
  return <div role="button"
    style={{
      ...buttonStyle,
      "marginTop": "15px",
      "fill": "white",
    }}
    onClick={onClick}>
    <div style={style} disabled>
      <Spinner
        as="span"
        animation="grow"
        size="sm"
        role="status"
        aria-hidden="true"
        style={{ "height": "12px", "width": "12px" }}
      />{label}
    </div>
  </div>
}

export default BuyingButton;