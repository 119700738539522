import { Col } from "react-bootstrap"
import Icon from "react-crypto-icons"
import { formatCompactAddress } from "../functions"
import useCopy from "../hooks/useCopy"
const bidLabelStyle =
{
  "height": "14px",
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "300",
  "fontSize": "12px",
  "lineHeight": "14px",
  "color": "#C558F3",
  "padding": "0px"
}
const bidLabelStyle2 =
{
  "marginTop": "12px"
}
const bidAmountTypography = {
  "fontFamily": "Roboto",
  "fontStyle": "normal",
  "fontWeight": "bold",
  "fontSize": "12px",
  "lineHeight": "14px",
  "color": "#000000"
}
const bidHistoryStyle = {
  "background": "#FFFFFF",
  "borderRadius": "4px",
  "marginTop": "4px",
  "paddingBottom": "10px",
}
const currentBidAddressStyle =
{
  "width": "127px",
  "height": "14px",
  "fontFamily": "Roboto",
  "fontStyle": "normal",
  "fontWeight": "500",
  "fontSize": "12px",
  "lineHeight": "14px",
  "color": "#55595D"
}
const currentBidTimeStyle =
{
  "height": "14px",
  "fontFamily": "Roboto",
  "fontStyle": "normal",
  "fontWeight": "normal",
  "fontSize": "12px",
  "lineHeight": "14px",
  "color": "#55595D"
}
const currentBidStyle = {
  "marginTop": "4px",
  "color": "black",
  "paddingTop": "8px",
  "paddingBottom": "8px",
  "fontFamily": "Roboto",
  "fontSize": "12px",
  "fontStyle": "normal",
  "fontWeight": "600",
  "lineHeight": "14px",
  "letterSpacing": "0em",
  "textAlign": "left",
  "overflow": "hidden",
  "background": "linear-gradient(106.65deg, #B1FF82 -6.26%, #3EF9AA 124.55%)",
  "borderRadius": "4px",
  "display": "flex",
  "justifyContent": "start"
}
const OfferDisplay= ({
  highestBidder,
  currentPrice,
  address,
}) => {
  const copy = useCopy()
  return <>
    <Col style={{ ...bidLabelStyle, ...bidLabelStyle2 }} xs={12}>
      Current offer amount</Col>
    <Col style={{...currentBidStyle }} xs={12}>
      <span style={{
        ...bidAmountTypography,
        "display": "inline-flex",
        "columnGap": "5px",
      }}>
        <Icon name="algo" size={12} /><span>{currentPrice}</span>
      </span>
    </Col>
  </>
}
export default OfferDisplay