import * as backend from '../build/index.main.mjs'
import moment from "moment"
import { useState } from "react"
import Tooltip from '@mui/material/Tooltip';
import {
  formatCompactAddress, formatCurrencyFromSome,
} from "../functions";
import JoinButton from "../components/buttons/JoinButton";
import Countdown from '../components/Countdown'
import BidHistory from "../components/BidHistory";
import HistoryLoader from "../loaders/HistoryLoader";
import ButtonLoader from "../loaders/ButtonLoader";
import PriceLoader from "../loaders/PriceLoader";
import OfferDisplay from "../components/OfferDisplay";
import { Col, Image, Row } from "react-bootstrap";
import BasicModal from "../components/BasicModal";
import APIButtons from "../components/buttons/APIButton";
import CloseButton from "../components/buttons/CloseButton";
import ClosingButton from '../components/buttons/ClosingButton';
import AuctionIndicators from '../components/AuctionIndicators'
import useCopy from '../hooks/useCopy';
import IndicatorLoader from '../loaders/IndicatorLoader';
import DeleteButton from '../components/buttons/DeleteButton';
import { getExplorer } from '../functions';
import { FeatureFlagContext } from '../context/FeatureFlagContext';
import OfferButton from '../components/buttons/OfferButton';
import BuyButton from '../components/buttons/BuyButton';
import UpdateButton from '../components/buttons/UpdateButton';
const bidStyle = {
  "padding": "24px",
  "background": "#F3F3F3",
  "color": "#888888",
  "fontFamily": "Roboto",
  "fontStyle": "normal",
  "fontWeight": "normal",
  "fontSize": "12px",
  "lineHeight": "14px"
}
const textLeftStyle = {
  "textAlign": "left"
}
const valueStyle =
{
  "height": "17px",
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "bold",
  "fontSize": "14px",
  "lineHeight": "17px",
  "color": "#C75AF3",
  "flex": "none",
  "order": "1",
  "flexGrow": "0",
  "margin": "0px 2px"
}
const labelStyle =
{
  "height": "17px",
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "300",
  "fontSize": "14px",
  "lineHeight": "17px",
  "color": "#484959",
  "flex": "none",
  "order": "0",
  "flexGrow": "0",
  "margin": "0px 2px"
}
const ReverseView = (props) => {
  const {
    ctc,
    onClose,
    addr,
    stdlib,
    platformAddr,
    closed,
    appId,
    token,
    owner,
    creator,
    endSecs,
    lowestBidder,
    currentPrice,
  } = props
  const copy = useCopy()
  const [loading, setLoading] = useState(props.loading)
  /*
  const [closing, setClosing] = useState(props.closing)
  const [query, setQuery] = useState({
    BID: Math.max(parseInt(props.startPrice), parseInt(props.currentPrice)) + 1
  })
  const { enqueueSnackbar } = useSnackbar()
  */
  //
  /*
  useEffect(() => {
    const closingInterval = setInterval(() => {
      if (props.endSecs < moment().unix()) {
        setClosing(true)
      }
    }, 1000)
    return () => clearInterval(closingInterval)
  }, [props.endSecs])
  */
  // STYLES
  const auctionViewCardStyle =
  {
    //"height": "333px",
    "background": "#FFFFFF",
    "boxShadow": "0px 2px 12px rgba(0, 0, 0, 0.1)",
    //"borderRadius": "12px",
    "borderRadius": "36px",
    "overflow": "hidden",
    "width": "90vw",
    "margin": "42px auto"
  }
  const NFTNameStyle =
  {
    //"height": "33px",
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "bold",
    "fontSize": "28px",
    "lineHeight": "33px",
    "color": "#55595D"
  }
  const appIdStyle =
  {
    "height": "17px",
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "normal",
    "fontSize": "14px",
    "lineHeight": "17px",
    "textAlign": "right",
    "color": "#55595D"
  }
  const ownerStyle =
  {
    "height": "17px",
    "fontFamily": "Rubik",
    "fontStyle": "italic",
    "fontWeight": "300",
    "fontSize": "14px",
    "lineHeight": "17px",
    "color": "#484959",
    "marginTop": "8px",
    "display": "inline-flex",
    "columnGap": "4px"
  }
  const valueContainerStyle =
  {
    "marginTop": "15px"
  }
  const descriptionLabelStyle =
  {
    "height": "14px",
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "300",
    "fontSize": "12px",
    "lineHeight": "14px",
    "color": "#C558F3",
    "marginTop": "16px"
  }
  const descriptionStyle =
  {
    "width": "95%",
    //"height": "161px",
    "background": "#FCFCFC",
    "border": "1px solid #F2F2F2",
    "boxSizing": "border-box",
    "borderRadius": "16px",
    "margin": "8px auto",
    "padding": "14px",
    "height": "161px",
    "overflow": "hidden"
  }

  const [query, setQuery] = useState({})
  const handleChange = ({ target }) => {
    let { name, value } = target
    console.log({ name, value })
    setQuery({ [name]: value })
  }

  const CurrentPrice = ({ cp }) => <div>
    {cp}
  </div>

  const NFTImage = (props) => {
    const [showModal, setShowModal] = useState(false)
    const explorer = getExplorer()
    return <>
      <BasicModal open={showModal} handleOpen={() => setShowModal(true)} handleClose={() => setShowModal(false)}>
        <Row>
          <Col xs={12} sm={6}>
            <Image src={props.image} fluid />
          </Col>
          <Col xs={12} sm={6}>
            <Row>
              <Col xs={12}>
                <span>APP ID:</span><a style={{ float: "right" }} href={`${explorer}/application/${appId}`} target="_blank" rel="noopener noreferrer">{appId}</a>
              </Col>
              <Col xs={12}>
                <span>ASSET ID:</span><a style={{ float: "right" }} href={`${explorer}/asset/${token}`} target="_blank" rel="noopener noreferrer">{token}</a>
              </Col>
              <Col xs={12}>
                <span>ESCROW:</span><a style={{ float: "right" }} href={`${explorer}/address/${addr}`} target="_blank" rel="noopener noreferrer">{formatCompactAddress(addr)}</a>
              </Col>
              <Col xs={12}>
                <span>CREATOR:</span><a style={{ float: "right" }} href={`${explorer}/address/${creator}`} target="_blank" rel="noopener noreferrer">{formatCompactAddress(creator)}</a>
              </Col>
            </Row>
          </Col>
        </Row>
      </BasicModal>
      <Col
        onClick={() => setShowModal(true)}
        className="d-xs-block d-md-none"
        style={{
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          padding: '0px'
        }} xs={12} md={3}>
        <Image src={props.image} alt="NFT" fluid />
      </Col>
      <Col
        onClick={() => setShowModal(true)}
        className="d-none d-md-block" style={{
          backgroundImage: `url(${props.image})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }} xs={12} md={3}>
        &nbsp;
      </Col>
    </>
  }
  const handleBuy = () => {
    //ctc.a.Bid.touch()
    //  .then(() =>
        ctc.a.Bid.acceptOffer()
          .then(() => console.log("ACCEPTED"))
          .catch(() => console.log("BID NOT ACCEPTED"))
          
    //  ).catch(() => console.log("NOT TOUCHED"))
  }
  const handleTouch = () => {
    ctc.a.Bid.touch()
  }
  return <FeatureFlagContext.Consumer>
    {({
      deleteButton,
    }) => <>
        <Row style={auctionViewCardStyle}>
          <NFTImage image={props.image} />
          <Col style={textLeftStyle} xs={12} md={6}>
            <Row>
              <Col style={{ "marginTop": "24px" }} xs={12}>
                <Row>
                  <Col xs={12} sm={8} style={NFTNameStyle}>
                    {props.name}
                  </Col>
                  <Col className={"d-none d-sm-block"} sm={4} style={appIdStyle}>
                    ID: {appId}
                  </Col>
                  <Col style={ownerStyle} xs={12}>
                    <span style={{ cursor: "pointer" }} onClick={() => copy(props.creator)}>{formatCompactAddress(props.creator)}</span>
                    <span className={"d-xs-block d-sm-none"}>{'/'} {appId}</span>
                  </Col>
                </Row>
              </Col>
              <Col style={valueContainerStyle} xs={12}>
                {false && <span style={labelStyle}>Amount of NFTs:<span style={valueStyle}>{props.circulatingsupply}</span></span>}
                {true && <span style={labelStyle}>Rarity:<span style={valueStyle}>1 of {props.total} available</span></span>}
                {false && <Countdown initialEndMoment={moment.unix(props.endSecs)} v={props.v} stdlib={props.stdlib} />}
              </Col>
              <Col xs={12}>
                {false && <IndicatorLoader id={props.appId} v={props.v} initialState={{
                  reservePrice: props.reservePrice,
                  roy: props.roy,
                  isReservePriceMet: parseFloat(props.currentPrice) > parseFloat(props.reservePrice),
                  isClosed: parseInt(props.endSecs) < moment().unix(),
                }} stdlib={stdlib}>
                  <AuctionIndicators />
                </IndicatorLoader>}
              </Col>
              {props.description && <>
                <Col style={descriptionLabelStyle} xs={12}>
                  Description
                </Col>
                <Col style={descriptionStyle} xs={12}>
                  <p>
                    {props.description}
                  </p>
                </Col>
              </>}
            </Row>
          </Col>
          <Col style={{
            ...bidStyle,
            ...textLeftStyle,
            "height": "400px",
            //"display": "flex",
            //"alignItems": "center"
          }} xs={12} md={3}>
            <Row>
              <PriceLoader acc={props.acc} v={props.v} stdlib={stdlib} >
                <OfferDisplay currentPrice={props.currentPrice} address={props.acc.address} />
              </PriceLoader>
              {false && <UpdateButton onClick={handleTouch} />}
              <BuyButton onClick={handleBuy}/>
            </Row>
          </Col>
        </Row>
        <>
          <input name="OFFERAMT" value={query.OFFERAMT} onChange={handleChange} />
          <button onClick={() => {
            ctc.v.Auction.currentPrice()
              .then(cp => formatCurrencyFromSome(stdlib, cp))
              .then(console.dir)
            ctc.a.Bid.getOffer(stdlib.parseCurrency(query.OFFERAMT))
              .then(k => console.log(k))
              .catch((e) => { })
          }}>Offer</button>
          {false && <input name="ROYALTYCENTS" value={query.ROYALTYCENTS} onChange={handleChange} />}
          <button onClick={() => {
            ctc.a.Bid.acceptOffer()
          }}>Accept</button>
          <button onClick={() => {
            ctc.a.Bid.touch()
              .then(() =>
                ctc.a.Bid.acceptOffer()
                  .then(() => console.log("ACCEPTED"))
                  .catch(() => console.log("BID NOT ACCEPTED")))
              .catch(() => console.log("NOT TOUCHED"))
          }}>Buy</button> 
          <button onClick={() => {
            ctc.a.Bid.touch()
          }}>Touch</button>
        </>
      </>}
  </FeatureFlagContext.Consumer>
}
export default ReverseView;