const Card = ({
  title,
  children, 
  style, 
  width
}) => {

  const cardStyle =
  {
    "background": "#FFFFFF",
    "borderRadius": "36px",
    "padding": "50px 56px",
    "width": `${width || 565}px`,
    "paddingBottom": "20px"
  }
  const compactCardStyle =
  {
    "top": "15%",
    "left": "5vw",
    "width": "90vw",
    "background": "#FFFFFF",
    "borderRadius": "36px",
    "padding": "20px 30px",
    "marginTop": "0vh",
  }
  const titleTypographyStyle = {
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "900",
    "fontSize": "32px",
    "lineHeight": "38px",
    "textAlign": "center",
    "letterSpacing": "0.1em",
    "textTransform": "uppercase",
    "color": "#2A3035"
  }
  return <>
    <div className="d-xs-block d-sm-none" style={{ ...compactCardStyle }}>
      <div style={titleTypographyStyle}>{title}</div>
      {children}
    </div>
    <div className="d-none d-sm-block" style={{ ...cardStyle, ...style }}>
      <div style={titleTypographyStyle}>{title}</div>
      {children}
    </div>
  </>
}

export default Card;