import { useEffect, useState } from "react"
import { Col, Image, Row } from "react-bootstrap"
import Icon from "react-crypto-icons"
import { useHistory } from "react-router"
import { getAssetImage } from "../functions"

const serviceNameTypographyStyle =
{
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "900",
  "fontSize": "32px",
  "lineHeight": "38px",
  "textAlign": "center",
  "letterSpacing": "0.1em",
  "textTransform": "uppercase"
}
const cardStyle =
{
  "marginTop": "5vh",
  "width": "391px",
  "background": "#FFFFFF",
  "borderRadius": "36px",
  "padding": "44px"
}
const compactCardStyle =
{
  "marginTop": "5vh",
  "width": "90vw",
  "background": "#FFFFFF",
  "borderRadius": "36px",
  "padding": "44px"
}
const imageStyle = {
  "marginTop": "26px"
}
const labelStyle = {
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "300",
  "fontSize": "12px",
  "lineHeight": "14px",
  "color": "#C558F3"
}
const valueStyle =
{
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "500",
  "fontSize": "14px",
  "lineHeight": "17px",
  "color": "#55595D",
  "marginTop": "5px"
}
const buttonContainerStyle =
{
  "display": "flex",
  "justifyContent": "center"
}
const buttonStyle =
{
  "width": "100%",
  "maxWidth": "304px",
  "height": "50px",
  "lineHeight": "50px",
  "background": "linear-gradient(111.85deg, #FB87FF -23.82%, #AE44ED 119.4%)",
  "boxShadow": "0px 10px 20px rgba(219, 134, 255, 0.66)",
  "borderRadius": "44px",
  "marginTop": "55px",
  "color": "#ffffff"
}
const sellMoreStyle =
{
  "height": "17px",
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "normal",
  "fontSize": "14px",
  "lineHeight": "17px",
  "textAlign": "center",
  "textTransform": "uppercase",
  "color": "#C054F2",
  "marginTop": "32px"
}
const Success = ({
  onOk,
	onSellMore,
  ASSETID,
  STARTPRICE,
  FLOORPRICE,
  STARTBID,
  RESERVEPRICE,
  DEADLINEDATE,
}) => {
  const history = useHistory()
	const [image, setImage] = useState(null)
	useEffect(() => {
	  if (!image) {
	    (async () =>
	      getAssetImage(ASSETID)
		.then(data => setImage(data)))()
	  }
	}, [image, ASSETID])

	const Template = () => {
	  const displayAlgo = (val) => <div style={{
	    "display": "inline-flex",
	    "columnGap": "5px"
	  }}>
	    <Icon name="algo" size={14} />
	    <span>{val}</span>
	  </div>
	  return <>
	    <div style={serviceNameTypographyStyle}>Success</div>
	    <div>
	      <Image style={imageStyle} src={image} alt="NFT" fluid />
	      <Row style={{ marginTop: '34px' }}>
		{[
		  {
		    label: "Asset id",
		    value: ASSETID,
		    displayValue: (val) => `${val}`,
		    visible: !!ASSETID
		  },
		  {
		    label: "Start price",
		    value: STARTPRICE,
		    displayValue: displayAlgo,
		    visible: !!STARTPRICE
		  },
		  {
		    label: "Floor price",
		    value: FLOORPRICE,
		    displayValue: displayAlgo,
		    visible: !!FLOORPRICE
		  },
		  {
		    label: "Start bid",
		    value: STARTBID,
		    displayValue: displayAlgo,
		    visible: !!STARTBID
		  },
		  {
		    label: "Reserve price",
		    value: RESERVEPRICE,
		    displayValue: displayAlgo,
		    visible: !!RESERVEPRICE
		  },
		  {
		    label: "Deadline date",
		    value: DEADLINEDATE,
		    displayValue: (val) => `${val}`,
		    visible: !!DEADLINEDATE
		  }
		].map(el => el.visible && <Col style={{ padding: '0px', height: '38px', overflow: 'hidden' }}>
		  <Row>
		    <Col xs={12} className="text-left" style={labelStyle}>{el.label}</Col>
		    <Col xs={12} style={valueStyle}>{el.value ? el.displayValue(el.value) : '-'}</Col>
		  </Row>
		</Col>)}
	      </Row>
	      <div style={buttonContainerStyle}>
		<div role="button" style={buttonStyle} onClick={onOk}>OK</div>
	      </div>
	      <div role="button" style={sellMoreStyle} onClick={onSellMore}>Sell One More NFT</div>
	    </div>
	  </>
	}
	return <>
	  <div className="d-none d-sm-inline-block" style={cardStyle}>
	    <Template />
	  </div>
	  <div className="d-xs-inline-block d-sm-none" style={compactCardStyle}>
	    <Template />
	  </div>
	</>
      }
export default Success