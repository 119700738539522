import { TextField } from "@mui/material"
import { Spinner } from "react-bootstrap"
const buttonStyle =
{
  "height": "42px",
  "lineHeight": "42px",
  "background": "linear-gradient(111.85deg, #FB87FF -23.82%, #AE44ED 119.4%)",
  "boxShadow": "0px 10px 20px rgba(219, 134, 255, 0.66)",
  "borderRadius": "44px",
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "normal",
  "fontSize": "14px",
  "textAlign": "center",
  "textTransform": "uppercase",
  "color": "#FFFFFF"
}
const LoadingButton = ({ variant, label = "Loading..." }) => {
  let style = buttonStyle
  switch (variant) {
    case 'claim':
      label = "Claiming NFT..."
      style = {
        ...style,
        "background": "linear-gradient(111.85deg, rgb(255 193 7 / 68%) -23.82%, #ffc107 119.4%)",
        "boxShadow": "#ffc10736 0px 10px 20px"
      }
      break
    default:
      label = "Loading..."
      style = {
        ...style,
        "marginTop": "15px",
        "opacity": ".9"
      }
  }
  return <>
    {variant !== "claim" && <TextField
      name="BID"
      id="input-with-icon-textfield"
      type="number"
      size="small"
      variant="standard"
      label="Bid amount"
      fullWidth={true}
      disabled={true} />}
    <div style={style} disabled>
      <Spinner
        as="span"
        animation="grow"
        size="sm"
        role="status"
        aria-hidden="true"
        style={{ "height": "12px", "width": "12px" }}
      />{label}
    </div>
  </>
}
export default LoadingButton;