import React, { useEffect, useState } from "react"
import moment from 'moment';
import {
  bigNumberToNumberFromSome,
  formatCurrencyFromSome,
  formatAddressFromSome,
} from '../functions'
const ButtonLoader = ({
  // TODO: Use interface
  acc,
  id,
  stdlib,
  children,
  v,
  a,
  apis,
  addr,
  onClose,
  initialState,
  owner,
}) => {
  const [state, setState] = useState(initialState)
  useEffect(() => {
    let { address } = acc
    let interval = setInterval(() => {
      Promise.all([
        v.endSecs(),
        v.currentPrice(),
        v.startPrice(),
        v.reservePrice(),
        v.highestBidder(),
        v.closed(),
        v.minBid(),
      ])
        .then(async ([
          endSecs,
          cp,
          sp,
          rp,
          hb,
          closed,
          minBid,
        ]) => {
          let fEndSecs = bigNumberToNumberFromSome(stdlib, endSecs)
          let fCp = formatCurrencyFromSome(stdlib, cp)
          let fSp = formatCurrencyFromSome(stdlib, sp)
          let fRp = formatCurrencyFromSome(stdlib, rp)
          let fHb = formatAddressFromSome(stdlib, hb)
          let fClosed = closed[0] === 'Some' ? closed[1] : true
          let fMinBid = formatCurrencyFromSome(stdlib, minBid)
          let showing = fEndSecs < moment().unix()
            ? (
        /*reserveBidMet*/ parseFloat(fCp) >= parseFloat(fRp)
                ? (
                  fHb === address
                    ? "claim"
                    : "close")
                : fHb === address || owner === address
                  ? "close"
                  : "buy")
            : "bid"
          console.log({buttonLoader: [state.showing, showing]})
          if (true || state.showing !== showing)
            console.log({buttonLoader: "update"})
            setState({
              // from ctc
              v,
              a,
              apis: a,
              // from acc
              acc,
              address,
              // from app
              id,
              appId: id,
              // from view
              endSecs: fEndSecs,
              cp: fCp,
              currentPrice: fCp,
              sp: fSp,
              startPrice: fSp,
              rp: fRp,
              reservePrice: fRp,
              hb: fHb,
              highestBidder: fHb,
              closed: fClosed,
              minBid: fMinBid,
              // from asset
              // from asset url (metadata)
              // derived
              showing
            })
        })
    }, 4000);
    return () => clearInterval(interval)
  }, [])
  return (
    React.Children.map(children, (child, i) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child,
          { ...state, key: id, onClose });
      }
      return child;
    })
  )
}
export default ButtonLoader;