import { useEffect, useRef } from 'react'
import {
  useHistory,
  useParams
} from "react-router-dom"
import * as backend from '../build/index.main.mjs'
import { useState } from "react";
import { Col, Image, Row } from "react-bootstrap"
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import { DateTimePicker } from '@mui/lab'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import ClockIcon from '@mui/icons-material/AccessTime';
import Icon from "react-crypto-icons";
import Slider from '@mui/material/Slider';
import {
  getMomentDiff
} from '../functions'
import './Sell.css'

const axios = require('axios')

const { REACT_APP_NETWORK_PROVIDER, REACT_APP_NETWORK } = process.env
const providerEnv = REACT_APP_NETWORK_PROVIDER || "TestNet"
let algoexplorerapi_endpoint
if (providerEnv === "MainNet") {
  algoexplorerapi_endpoint = 'https://algoexplorerapi.io'
} else {
  algoexplorerapi_endpoint = 'https://testnet.algoexplorerapi.io'
}

const getAsset = async (assetIndex) =>
  await axios.get(`${algoexplorerapi_endpoint}/v1/asset/${assetIndex}`)

const getUrlPath = (url) =>
  ((delimeter) =>
    url.split(delimeter).slice(2).join(delimeter))
    ('/')

const getCFIPFSUrl = (path) =>
  `https://cloudflare-ipfs.com/ipfs/${path}`

const getCFIPFS = async (path) =>
  await axios.get(getCFIPFSUrl(path))

const getAssetImage = async (assetIndex) => {
  let image
  try {
    const asset = await getAsset(assetIndex)
    const { url } = asset.data
    if (url.indexOf('https://') !== -1) {
      return url
    }
    else if (url.indexOf('ipfs') !== -1) {

      if (url.indexOf('json') !== -1) {
        // url is metadata json with relative path to image
        const maybeImage = (await getCFIPFS(getUrlPath(url))).data
        var path = require('path')
        image = [path.dirname(url), maybeImage.image].join('/')
      } else {
        if (url.indexOf('pinata') === -1) {
          // TODO url is another ipfs resource
          const maybeMetadata = (await getCFIPFS(getUrlPath(url))).data
          if (maybeMetadata.image.indexOf('ipfs') !== -1) {
            // use gateway url
            image = getCFIPFSUrl(getUrlPath(maybeMetadata.image))
          } else {
            // use url as is
            image = maybeMetadata.image
          }
        } else {
          // pinata url contains image
          image = url
        }
      }

    } else {
      // url may be gateway url
      image = url
    }
  } catch (e) {
    // use placeholder as fallback
    image = 'https://via.placeholder.com/1024'
  }
  return image
}

function BasicSelect(props) {
  const handleChange = props.handleChange
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <Select
          name={props.name}
          value={props.value}
          variant={props.variant}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={props.label}
          onChange={handleChange}
          disabled={props.disabled}
        >
          {props.items.map(el =>
            <MenuItem value={el["asset-id"]}>{props.displayItem(el)}</MenuItem>)}
        </Select>
      </FormControl>
    </Box>
  );
}

const getNodeStatus = async () => {
  let { status, data } = await axios.get(`${algoexplorerapi_endpoint}/v2/status`)
  if (status === 200) {
    return data
  }
  return null
}

const Sell = (props) => {
  var moment = require('moment-timezone');
  document.title = "Sell - NFT Jam"
  let history = useHistory()
  let { appId } = useParams()
  const sliderRef = useRef(null)
  let sliderTimeout
  const {
    acc,
    stdlib,
    ASSET_ID_JAM,
    ADDR_PLATFORM,
    ADDR_DISCOVERY,
    ADDR_DISCOVERY2,
    ADDR_PRIVATE,
  } = props
  const [sliderProps, setSliderProps] = useState({})
  const [networkTime, setNetworkTime] = useState(0)
  const [state, setState] = useState({
    success: false,
    loading: false,
    bank: null,
    buy: null,
    seconds: 0,
    step: 0
  })
  const [query, setQuery] = useState({
    ASSETID: 0,
    STARTBID: 1,
    RESERVEPRICE: 100,
    DEADLINEDATE: moment().add(1, 'day'),
    ENABLEDISCOVERY: true,
    ROYALTYCENTS: 10
  })
  const getRoyaltyParams = rc => ({
    royaltyAddr: rc > 0
      ? String(query.CREATOR)
      : ADDR_PLATFORM,
    royaltyCents: rc === 0
      ? 1
      : rc
  })
  const handleSubmit = async () => {
    // validate sell nft form
    if (query.ASSETID === 0) {
      alert("Must select asset")
      return
    }
    else if (query.STARTBID <= 0) {
      alert("Start bid must be greater than zero")
      return
    }
    else if (query.RESERVEPRICE <= query.STARTBID) {
      alert("Reserve price must be greater tha start price")
      return
    }
    else if (moment().unix() > query.DEADLINEDATE.unix()) {
      alert("Deadline date must be in the future")
      return
    }
    else if (parseInt(query.ROYALTYCENTS) < 0) {
      alert(`Royalty % must be greater than or equal to 0`)
      return
    }
    else if (parseInt(query.ROYALTYCENTS) >= 99) {
      alert("Royalty % must be less than 99")
      return
    }

    let ctcAuctionInfo
    const getParams = () => ({
      addr: ADDR_PLATFORM,
      addr2: query.ENABLEDISCOVERY
        ? ADDR_DISCOVERY : ADDR_PRIVATE,
      addr3: ADDR_DISCOVERY2,
      ...getRoyaltyParams(query.ROYALTYCENTS),
      discoveryEnabled: query.ENABLEDISCOVERY,
      tokB: ASSET_ID_JAM,
      token: parseInt(query.ASSETID),
      reservePrice: stdlib.parseCurrency(query.RESERVEPRICE), // 100 ALGO
      startPrice: stdlib.parseCurrency(query.STARTBID), // 0 ALGO
      bidIncrementAbs: stdlib.parseCurrency("1"), // 1 ALGO
      bidIncrementRel: 1, // 1% 
      deadline: 50, // 50 blocks
      deadlineSecs: query.DEADLINEDATE.unix(), // x secs 
      maxDeadline: 1000, // y blocks, where y > x
      unitAmount: stdlib.parseCurrency(1),
      deadlineStep: 10
    })
    const signal = async () => {
      let image = await getAssetImage(parseInt(query.ASSETID))
      console.log({ image })
      setState({
        ...state,
        //loading: true,
        success: true,
        appId: ctcAuctionInfo,
        image
      })
    }
    const closed = () => {
      console.log("CLOSED")
    }
    const commonInteract = {
      ...stdlib.hasConsoleLogger
    }
    const auctioneerInteract = {
      ...commonInteract,
      getParams,
      signal,
      closed
    }
    // DEBUG
    //console.log(stdlib.bigNumberToNumber(await stdlib.getNetworkSecs()))
    //console.log(getParams())
    if (!appId) {

      setState({ ...state, loading: true })
      console.log("Launching new auction ...")
      // TODO depreciated
      //const ctcAuction = acc.deploy(backend)
      const ctcAuction = acc.contract(backend)
      Promise.all([
        //backend.Constructor(ctcAuction, constructorInteract),
        backend.Auctioneer(ctcAuction, {
          ...stdlib.hasConsoleLogger,
          getParams: () => ({
            addr: ADDR_PLATFORM,
            addr2: query.ENABLEDISCOVERY
              ? ADDR_DISCOVERY : ADDR_PRIVATE,
            ...getRoyaltyParams(query.ROYALTYCENTS),
            addr3: ADDR_DISCOVERY2,
            discoveryEnabled: query.ENABLEDISCOVERY,
            royaltyAddr: (rc => rc > 0 ? String(query.CREATOR) : query.ALLOWDISCOVERY ? ADDR_PRIVATE : ADDR_PLATFORM)(query.ROYALTYCENTS),
            royaltyCents: (rc => rc === 0 ? 1 : rc)(query.ROYALTYCENTS),
            tokB: ASSET_ID_JAM,
            token: parseInt(query.ASSETID || 24270812),
            reservePrice: stdlib.parseCurrency(query.RESERVEPRICE), // 100 ALGO
            startPrice: stdlib.parseCurrency(query.STARTBID), // 0 ALGO
            bidIncrementAbs: stdlib.parseCurrency("1"), // 1 ALGO
            bidIncrementRel: 1, // 1% 
            deadline: 50, // 50 blocks
            deadlineSecs: query.DEADLINEDATE.unix(), // x secs 
            maxDeadline: 1000, // y blocks, where y > x
            unitAmount: stdlib.parseCurrency(1),
            deadlineStep: 10
          }),
          signal: async () => {
            let image = await getAssetImage(parseInt(query.ASSETID))
            console.log({ image })
            setState({
              ...state,
              //loading: true,
              success: true,
              appId: ctcAuctionInfo,
              image
            })
          },
          closed: () => {
            console.log("CLOSED")
          }
        })
      ])
      ctcAuctionInfo = await ctcAuction.getInfo()
      appId = ctcAuctionInfo
      //history.push(`/sell/${appId}`)
      //setState({ ...state, loading: false })
    } else {

      if (!query.ASSETID
        || !query.STARTBID
        || !query.RESERVEPRICE
        || !query.DEADLINEDATE) {
        alert("Enter Asset id, Start bid, and Deadline date to submit")
        return
      }
      setState({ ...state, loading: true })
      ctcAuctionInfo = parseInt(appId)
      console.log("Launching auction ...")
      // TODO depreciated
      //let ctcAuction = acc.attach(backend, ctcAuctionInfo)
      let ctcAuction = acc.contract(backend, ctcAuctionInfo)
      Promise.all([
        backend.Auctioneer(ctcAuction, auctioneerInteract)
      ])
      appId = ctcAuctionInfo

    }
  }
  const initialState = {
    step: appId ? 1 : 0,
    loading: false
  }
  useEffect(() => {
    if (state.success) return
    let interval
    (async () => {
      interval = setInterval(async () => {
        //setNetworkTime(stdlib.bigNumberToNumber(await stdlib.getNetworkTime()))
        let status = await getNodeStatus()
        if (status) {
          setNetworkTime(status['last-round'])
        }
      }, 1000 * 5)
    })()
    return () => clearInterval(interval)
  }, [state.success])
  /*
  const somethingFromSome = (f, d) => (some) => some[1] ? f(some[1]) : d
  const bigNumberToNumberFromSome = (some) => somethingFromSome((some) => stdlib.bigNumberToNumber(some), 0)(some)
  */
  const SubmitButton = (props) => {
    const {
      loading,
      label,
      onClick
    } = props
    return loading
      ? (
        <div style={{
          ...buttonStyle,
          ...buttonTypographyStyle,
          ...{
            "marginTop": "40px",
            "opacity": ".9"
          }
        }}
          disabled><Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
            style={{
              "height": "12px",
              "width": "12px"
            }}
          />Loading...</div>
      )
      : (
        <div role="button" style={{ ...buttonStyle, ...buttonTypographyStyle, ...{ "marginTop": "40px" } }} onClick={() => { onClick() }}>
          {label}
        </div>
      )
  }
  const handleChange = ({ target }) => {
    let { name, value } = target
    console.log({ name, value })
    switch (name) {
      case 'ASSETID':
        let CREATOR = acc.assets.filter(el => el['asset-id'] === value)[0].creator
        if (CREATOR === acc.address) {
          setQuery({
            ...query,
            [name]: value,
            CREATOR,
            ROYALTYCENTS: 0,
          })
        } else {
          setQuery({
            ...query,
            [name]: value,
            CREATOR
          })
        }
        break
      case 'STARTBID':
      case 'RESERVEPRICE':
      case 'ROYALTYCENTS':
        if (value.length > 1 && value[0] === '0') {
          value = value.slice(1)
        }
        setQuery({
          ...query,
          [name]: value
        })
        break
      default:
    }
  }
  const handleSellOneMore = () => {
    appId = null
    history.push(`/sell`)
    setState({
      success: false
    })
    setQuery({})
  }
  const serviceNameTypographyStyle =
  {
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "900",
    "fontSize": "32px",
    "lineHeight": "38px",
    "textAlign": "center",
    "letterSpacing": "0.1em",
    "textTransform": "uppercase"
  }
  const cardStyle =
  {
    "background": "#FFFFFF",
    "borderRadius": "36px",
    "padding": "50px 56px",
    "width": "565px",
    //"height": "645px",
    "paddingBottom": "50px"
  }
  const compactCardStyle =
  {
    "position": "fixed",
    "top": "15%",
    "left": "5vw",
    "width": "90vw",
    "background": "#FFFFFF",
    "borderRadius": "36px",
    "padding": "20px 30px",
    "marginTop": "0vh",
    //"maxWidth": "90vw",
    //"maxHeight": "70vh",
    //"width": "465px",
    //"height": "588px"
  }

  const Success = ({ assetId }) => {
    const [image, setImage] = useState(null)
    useEffect(() => {
      if (!image) {
        (async () =>
          getAssetImage(assetId)
            .then(data => setImage(data)))()
      }
    }, [image, assetId])
    const cardStyle =
    {
      "marginTop": "5vh",
      "width": "391px",
      "background": "#FFFFFF",
      "borderRadius": "36px",
      "padding": "44px"
    }
    const compactCardStyle =
    {
      "marginTop": "5vh",
      "width": "90vw",
      "background": "#FFFFFF",
      "borderRadius": "36px",
      "padding": "44px"
    }
    const imageStyle = {
      "marginTop": "26px"
    }
    const labelStyle = {
      "fontFamily": "Rubik",
      "fontStyle": "normal",
      "fontWeight": "300",
      "fontSize": "12px",
      "lineHeight": "14px",
      "color": "#C558F3"
    }
    const valueStyle =
    {
      "fontFamily": "Rubik",
      "fontStyle": "normal",
      "fontWeight": "500",
      "fontSize": "14px",
      "lineHeight": "17px",
      "color": "#55595D",
      "marginTop": "5px"
    }
    const buttonContainerStyle =
    {
      "display": "flex",
      "justifyContent": "center"
    }
    const buttonStyle =
    {
      "width": "100%",
      "maxWidth": "304px",
      "height": "50px",
      "lineHeight": "50px",
      "background": "linear-gradient(111.85deg, #FB87FF -23.82%, #AE44ED 119.4%)",
      "boxShadow": "0px 10px 20px rgba(219, 134, 255, 0.66)",
      "borderRadius": "44px",
      "marginTop": "55px",
      "color": "#ffffff"
    }
    const sellMoreStyle =
    {
      "height": "17px",
      "fontFamily": "Rubik",
      "fontStyle": "normal",
      "fontWeight": "normal",
      "fontSize": "14px",
      "lineHeight": "17px",
      "textAlign": "center",
      "textTransform": "uppercase",
      "color": "#C054F2",
      "marginTop": "32px"
    }
    const Template = () => {
      const displayAlgo = (val) => <div style={{
        "display": "inline-flex",
        "columnGap": "5px"
      }}>
        <Icon name="algo" size={14} />
        <span>{val}</span>
      </div>
      return <>
        <div style={serviceNameTypographyStyle}>Success</div>
        <div>
          <Image style={imageStyle} src={image} alt="NFT" fluid />
          <Row style={{ marginTop: '34px' }}>
            {[
              {
                label: "Asset id",
                value: query.ASSETID,
                displayValue: (val) => `${val}`
              },
              {
                label: "Start bid",
                value: query.STARTBID,
                displayValue: displayAlgo
              },
              {
                label: "Reserve price",
                value: query.RESERVEPRICE,
                displayValue: displayAlgo
              },
              {
                label: "Deadline date",
                value: query.DEADLINEDATE,
                displayValue: (val) => `${val}`
              }
            ].map(el => <Col style={{ padding: '0px', height: '38px', overflow: 'hidden' }}>
              <Row>
                <Col xs={12} className="text-left" style={labelStyle}>{el.label}</Col>
                <Col xs={12} style={valueStyle}>{el.value ? el.displayValue(el.value) : '-'}</Col>
              </Row>
            </Col>)}
          </Row>
          <div style={buttonContainerStyle}>
            <div role="button" style={buttonStyle} onClick={() => history.push(`/auction/${state.appId}`)}>OK</div>
          </div>
          <div role="button" style={sellMoreStyle} onClick={handleSellOneMore}>Sell One More NFT</div>
        </div>
      </>
    }
    return <>
      <div className="d-none d-sm-inline-block" style={cardStyle}>
        <Template />
      </div>
      <div className="d-xs-inline-block d-sm-none" style={compactCardStyle}>
        <Template />
      </div>
    </>
  }
  const labelTypographyStyle =
  {
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "300",
    "fontSize": "12px",
    "lineHeight": "14px",
    "color": "#C558F3"
  }
  const labelStyle =
  {
    "height": "14px",
    "marginBottom": "4px"
  }
  const buttonStyle =
  {
    "height": "50px",
    "background": "linear-gradient(111.85deg, #FB87FF -23.82%, #AE44ED 119.4%)",
    "boxShadow": "0px 10px 20px rgba(219, 134, 255, 0.66)",
    "borderRadius": "44px",
    "marginTop": "46px"
  }
  const buttonTypographyStyle =
  {
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "normal",
    "fontSize": "14px",
    "lineHeight": "50px",
    "textAlign": "center",
    "textTransform": "uppercase",
    "color": "#FFFFFF"
  }
  const cancelStyle =
  {
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "normal",
    "fontSize": "14px",
    "lineHeight": "17px",
    "textAlign": "center",
    "textTransform": "uppercase",
    "color": "#FF4747",
    "marginTop": "32px"
  }
  return <div id="sell" style={{ paddingBottom: "100px" }}>
    {!state.success ? <>
      <div className="d-xs-block d-sm-none" style={{ ...compactCardStyle }} >
        <div style={{ ...serviceNameTypographyStyle, ...{ "fontSize": "22px" } }}>Sell Your NFT</div>
        <Stack className="mt-3" spacing={4}>
          <Box style={{ "textAlign": "left" }}>
            {!appId
              ? <label style={{ ...labelStyle, ...labelTypographyStyle, "color": "grey" }}>{"Asset id"}</label>
              : <label style={{ ...labelStyle, ...labelTypographyStyle }}>{"Asset id"}</label>}
            <BasicSelect
              name="ASSETID"
              value={query.ASSETID}
              handleChange={handleChange}
              items={acc?.assets?.filter(el => el.amount > 0) || []}
              displayItem={(el) => ((assets) => assets.length > 0 ? ((asset) => `${asset.index} : ${asset?.params?.name} (${asset?.params["unit-name"]}) : ${el.amount}`)(assets[0]) : "")(((assetId) => acc.assetInfo.filter(el => el.index === assetId))(el["asset-id"]))}
              variant="standard" />
          </Box>
          {[
            {
              name: "STARTBID",
              label: "Start bid"
            },
            {
              name: "RESERVEPRICE",
              label: "Reserve price"
            },
            {
              name: "ROYALTYCENTS",
              label: "Royalty (%)"
            }
          ].map(el =>
            <Box style={{ "textAlign": "left" }}>
              {!appId
                ? <label style={{ ...labelStyle, ...labelTypographyStyle, "color": "grey" }}>{el.label}</label>
                : <label style={{ ...labelStyle, ...labelTypographyStyle }}>{el.label}</label>}
              <TextField
                name={el.name}
                id="standard-number"
                fullWidth={true}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="standard"
                value={query[el.name]}
                onChange={handleChange}
              />
            </Box>)}
          <Box style={{ "textAlign": "left" }}>
            {!appId
              ? <label style={{ ...labelStyle, ...labelTypographyStyle, "color": "grey" }}>{"Deadline date"}</label>
              : <label style={{ ...labelStyle, ...labelTypographyStyle }}>{"Deadline date"}</label>}
            <DateTimePicker
              name="DEADLINEDATE"
              value={query.DEADLINEDATE}
              onChange={(date) => setQuery({ ...query, DEADLINEDATE: date })}
              renderInput={(params) =>
                <TextField
                  {...params}
                  fullWidth={true}
                  variant="standard" />}
            />
          </Box>
          <SubmitButton onClick={handleSubmit} label={appId ? "Step 2: Configure Auction" : "Step 1: Create Auction"} loading={state.loading} />
          <div role="button" style={cancelStyle} onClick={() => history.push("/")}>Cancel</div>
        </Stack>
      </div>
      <div id="sell" className="d-none d-sm-block" style={cardStyle}>
        <div style={serviceNameTypographyStyle}>Sell Your NFT</div>
        <Stack className="mt-4 pt-1" spacing={5}>
          <Box style={{ "textAlign": "left" }}>
            <p>
              Choose the auction to sell your nft.
            </p>
            <p>
              <strong>Auction</strong> starts at some lower price increasing with each bid.
            </p>
            <p>
              <strong>Reverse auction</strong> start at some higher price decreasing as time runs out.
            </p>
            <p>
              Read more in docs regarding <a href="#">auction types</a>.
            </p>
            <SubmitButton as={Box} onClick={() => history.push("/sell/auction")} label="Auction" />
            <SubmitButton as={Box} onClick={() => history.push("/sell/reverse")} label="Reverse Auction" />
          </Box>
        </Stack>
      </div>
    </> : <Success assetId={query.ASSETID} />
    }
  </div >
}

export default Sell;