import React, { useEffect, useState } from "react"
import moment from 'moment';
import {
  getAccountTransaction,
} from '../functions'
const HistoryLoader = ({
  // TODO: Use interface
  acc,
  id,
  stdlib,
  addr,
  initialBidHistory,
  initialLastBid,
  initialIsAuctionOver,
  children
}) => {
  const [state, setState] = useState({
    showing: true,
    bidHistory: initialBidHistory,
    lastBid: initialLastBid,
    isAuctionOver: initialIsAuctionOver
  })
  useEffect(() => {
    let bidHistoryDepth = initialBidHistory.length
    let timeout = 7000
    let interval = setInterval(() => {
      (async () => {
        const transactions = (await getAccountTransaction(addr))?.data?.transactions
        const [lastBid, ...bidHistory]
          = ((transactions) =>
            transactions
              .filter(el => true
                && el['tx-type'] === 'pay'
                && el['payment-transaction'].receiver === addr)
              .map((el) => ({
                time: moment.unix(el['round-time']).format('LTS'),
                sender: el?.sender,
                amount: stdlib.formatCurrency(el["payment-transaction"]?.amount, 4)
              }))
              .slice(0, -3)
          )(transactions)
        const newBidHistory = bidHistory.slice(0,4)
        const newBidHistoryDepth = newBidHistory.length
        if(bidHistoryDepth >= newBidHistory.length) {
          console.log({bidHistoryLoader: "pass"})
          return
        }
        bidHistoryDepth = newBidHistoryDepth
        setState({
          showing: true,
          bidHistory: newBidHistory,
          lastBid,
        })
      })()
    }, timeout)
    return () => clearInterval(interval)
  }, [stdlib, acc, addr, id, state])
  return (
    state
      ? state.showing && React.Children.map(children, (child, i) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child,
            { ...state, key: i });
        }
        return child;
      })
      : <div id="loading"></div>
  )
}
export default HistoryLoader;